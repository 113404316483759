import React, { Component } from 'react';
import GoogleMaps from '../GoogleMaps';
import { connect } from "react-redux";

class MapComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMapStyle: 'grey',
            map: {},
            dataLayers: [],
            infowindow: null,
            fitler: {
                service: props.filter.service,
                provider: props.filter.provider,
                status: props.filter.status
            },
            mapOptions: {
                center: { lat: -26, lng: 25 },
                zoom: 6,
                minZoom: 3,
                maxZoom: 20,
                scaleControl: false,
                clickableIcons: false,
                mapTypeControl: true,
                zoomControl: true,
                streetViewControl: false,
                styles: props.mapStyle,
            },
            mapFilter: [],
            apikey: process.env.REACT_APP_GAPI,
            marker: null,
            mapLoading: false,
            displayMapLoad: false
        }
    }

    handleMapLoad = (map) => {
        this.setState({ map: map }, (state) => {
            this.handleDataLayers();
            if (this.props.handleMapLoad) this.props.handleMapLoad();
            this.addMapClickListener();

            let options = this.state.mapOptions;
            options.fullscreenControl = false;
            options.mapTypeControlOptions = {
                position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                mapTypeIds: [
                    window.google.maps.MapTypeId.ROADMAP,
                    window.google.maps.MapTypeId.HYBRID,
                    window.google.maps.MapTypeId.TERRAIN,
                    window.google.maps.MapTypeId.SATELLITE
                ]
            }
            options.zoomControlOptions = {
                position: window.google.maps.ControlPosition.RIGHT_CENTER
            }

            this.setState({ mapOptions: options })
            this.state.map.setOptions(options)
            this.initialiseGoogleAutocomplete()
        });
    }

    initialiseGoogleAutocomplete = (input) => {
        if (this.state.map && input) {
            let self = this;
            // let input = document.getElementById('pac-input');
            let options = {
                componentRestrictions: {
                    country: 'ZA'
                },
                fields: ["address_components", "formatted_address", "geometry"]
            };

            let autocomplete = new window.google.maps.places.Autocomplete(input, options);
            autocomplete.bindTo('bounds', this.state.map);
            autocomplete.addListener('place_changed', function () {
                let place = autocomplete.getPlace();
                if (place && place.geometry) {
                    let pos = new window.google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
                    self.goToPosition(pos);
                    self.updateMarker(pos);
                    if (self.props.onPlaceSearch) self.props.onPlaceSearch(autocomplete.getPlace());
                }
            });
        }
    }

    goToPosition = (pos) => {
        this.state.map.setZoom(16);
        this.state.map.panTo(pos);
    }

    updateMapStyle = (mapStyle) => {
        let options = this.state.mapOptions;
        options.styles = mapStyle;//this.props.mapStyle;
        this.setState({ mapOptions: options })
        this.state.map.setOptions(this.state.mapOptions)
    }

    handleLayer = (layer) => {
        // console.log(this.state.map);
    }

    handleDataLayers = (layer) => {
        if (this.state.map && layer) {
            let self = this;
            let datalayers = this.state.dataLayers;
            if (datalayers[layer.name]) {
                datalayers[layer.name].setMap(this.state.map);
            } else {
                let dataLayer = new window.google.maps.Data();
                dataLayer.addGeoJson(JSON.parse(layer.data));
                dataLayer.setMap(this.state.map);
                datalayers[layer.name] = dataLayer;
                dataLayer.setStyle(function (feature) {
                    var type = feature.getProperty('type');
                    type = type.toLowerCase();
                    var color = 'yellow';
                    if (type === 'loss') color = 'red';
                    else if (type === 'gain') color = 'green';

                    feature.setProperty('type_display', type);
                    if (type === 'status') feature.setProperty('type_display', 'Area status changed');
                    else if (type === 'loss') feature.setProperty('type_display', 'Area lost');
                    else if (type === 'gain') feature.setProperty('type_display', 'Area gained');

                    return {
                        icon: window.location.origin + '/images/' + color + '-dot.png'
                    };
                });
                dataLayer.addListener('click', function (event) {
                    self.updateInfoWindow(event.latLng, self.handleFeatureInfowindow(event.feature));
                });
            }
            this.setState({ dataLayers: datalayers });
            this.manageFilter();
        }
    }

    manageFilter = () => {
        if (this.state.mapFilter && this.state.mapFilter.length > 0) {
            this.state.mapFilter.forEach((value, index, selfV) => {
                this.filterDataLayers(value, selfV);
            })
        }
    }

    handleFilter = (feature, filter = null) => {
        if (!filter) filter = this.state.filter
        let visible = true
        if (filter) {
            if (filter.service) visible = feature.getProperty('service') === filter.service;
            if (filter.provider) {
                visible = filter.provider.includes(feature.getProperty('provider'));
            }
            if (filter.status) visible = feature.getProperty('status') === filter.status;
        }
        return visible
    }

    filterDataLayers = (filter, layerName) => {
        if (!filter || !layerName) return;
        let mapFilters = this.state.mapFilter;
        mapFilters[layerName] = filter;
        this.setState({ manageFilter: mapFilters })

        let self = this;
        let datalayers = this.state.dataLayers;
        let dataLayer = datalayers[layerName];
        if (dataLayer) {
            dataLayer.revertStyle();
            dataLayer.forEach(function (feature) {
                dataLayer.overrideStyle(feature, { visible: self.handleFilter(feature, filter) });
            });

            dataLayer.overrideStyle(function (feature) {
                return {
                    visible: self.handleFilter(feature, filter)
                };
            });
        }
    }

    updateInfoWindow = (pos, content) => {
        // let content = this.handleFeatureInfowindow(event.feature);
        let infowindow = this.state.infowindow;
        if (!infowindow) infowindow = new window.google.maps.InfoWindow();
        infowindow.close();
        infowindow.setContent(content);
        infowindow.setPosition(pos);
        infowindow.setOptions({ pixelOffset: new window.google.maps.Size(0, -34) });
        infowindow.open(this.state.map);
        this.setState({ infowindow: infowindow })
    }

    handleFeatureInfowindow = (feature) => {
        let grid_id = feature.getProperty('grid_id'),
            province = feature.getProperty('province'),
            suburb = feature.getProperty('suburb'),
            town = feature.getProperty('town'),
            service = feature.getProperty('service'),
            provider = feature.getProperty('provider'),
            status = feature.getProperty('status'),
            type = feature.getProperty('type'),
            type_display = feature.getProperty('type_display'),
            latitude = feature.getProperty('latitude'),
            longitude = feature.getProperty('longitude'),
            created_at = feature.getProperty('created_at');
        let times = created_at.split('T');
        if (!times || times.length == 0) times = [created_at]
        return `
                <div class='infowindow-header  ${type}'>
                    <strong>${this.getDisplayProvider(provider)}</strong>
                </div>
                <div class='infowindow-content'>
                    <b>Service: </b>${this.getDisplayService(service)}<br>
                    <b>Status: </b>${this.getDisplayStatus(status)}<br>
                    <b>Change type: </b>${type_display}<br>
                    <hr class="MuiDivider-root" style="margin: 5px 0px;"/>
                    <b>Province: </b>${province}<br>
                    <b>Suburb: </b>${suburb}<br>
                    <b>Town: </b>${town}<br>
                    <hr class="MuiDivider-root" style="margin: 5px 0px;"/>
                    <b>Updated at: </b>${times[0]}<br>
                </div
                `;
    }

    getDisplayProvider = (name) => {
        if (this.props.applicationData && !this.props.applicationData.loading) {
            let provider = this.props.applicationData.providers.filter(val => val.name === name)
            if (provider && provider.length > 0)
                return provider[0].full_name;
        }
        return name;
    }

    getDisplayStatus = (name) => {
        if (this.props.applicationData && !this.props.applicationData.loading) {
            let status = this.props.applicationData.statuses.filter(val => val.name === name)
            if (status && status.length > 0)
                return status[0].full_name;
        }
        return name;
    }

    getDisplayService = (name) => {
        if (this.props.applicationData && !this.props.applicationData.loading) {
            let service = this.props.applicationData.services.filter(val => val.name === name)
            if (service && service.length > 0)
                return service[0].full_name;
        }
        return name;
    }

    handleMarkerInfowindow = (data) => {
        let content = '<div class="infowindow-content" ><strong>Loading ...</strong></div>'

        if (data && data.services && data.services.length > 0) {
            content = ''
            for (let item in data.services) {
                if (data.services[item].providers.length > 0) {
                    content += `<div class='infowindow-service-header'>${this.getDisplayService(data.services[item].type)} </div>`
                    if (data.services[item].providers.length > 0) {
                        for (let p in data.services[item].providers) {
                            content += `
                            <div class="infowindow-service-content" >
                                <b>Provider: </b> ${this.getDisplayProvider(data.services[item].providers[p].provider)} <br>
                                <b>Status: </b> ${this.getDisplayStatus(data.services[item].providers[p].status)}
                            </div>
                            `;
                        }
                    }
                    // content += '<hr class="MuiDivider-root" style="margin: 5px 0px;"/>'
                }
            }
        } else if (data) {
            content = '<div class="infowindow-content" ><strong>' + data + '</strong></div>';
        } else {
            content = '';
        }
        if (!content) {
            content = '<div class="infowindow-content" ><strong>No services</strong></div>';
        }

        // return `<div class='infowindow-content'>${content}</div>`;
        return `<div>${content}</div>`;
    }

    removeDataLayer = (name) => {
        let datalayers = this.state.dataLayers;
        if (datalayers[name]) {
            datalayers[name].setMap(null)
        }
        // delete datalayers[name];
        this.setState({ dataLayers: datalayers })
    }

    removeDataLayers = () => {
        let datalayers = this.state.dataLayers;
        datalayers.map(layer => { layer.setMap(null) })
        this.setState({ dataLayers: [] })
    }

    addWMSLayers(layerIndex = 0, layerName) {
        let google = window.google;
        let map = this.state.map;
        let self = this;
        // if(!google || !map) return;
        let wms = new google.maps.ImageMapType({
            getTileUrl: function (coord, zoom) {

                let s = Math.pow(2, zoom);
                //latlng bounds of the 4 corners of the google tile
                let bottom_left = map.getProjection().fromPointToLatLng(
                    new google.maps.Point(coord.x * 256 / s, (coord.y + 1) * 256 / s)); // bottom left / SW
                let top_right = map.getProjection().fromPointToLatLng(
                    new google.maps.Point((coord.x + 1) * 256 / s, coord.y * 256 / s)); // top right / NE

                let layers = self.props.displayLayers;
                let styles = self.props.displayStyles;
                if (layerName) {
                    layers = layerName.name;
                    styles = layerName.style;
                }

                let bbox = bottom_left.lng() + "," + bottom_left.lat() + "," + top_right.lng() + "," + top_right.lat();
                return process.env.REACT_APP_WMS_BASE_URL + 'wms?' +
                    'service=WMS&' +
                    'version=1.1.0&' +
                    'request=GetMap&' +
                    'layers=' + layers + '&' +
                    'styles=' + styles + '&' +
                    'bbox=' + bbox + '&' +
                    'width=256&' +
                    'height=256&' +
                    'srs=EPSG:3857&' +
                    'format=image/png' +
                    '&TRANSPARENT=true' +
                    self.props.viewparams
            },
            tileSize: new google.maps.Size(256, 256),
            isPng: true,
            opacity: 0.6,
            maxZoom: 21,
            minZoom: 0,
            name: layerName ? layerName : 'layerName'
        });

        // map.overlayMapTypes.setAt(0, wms);
        map.overlayMapTypes.setAt(layerIndex, wms);
    }

    foreceMapRefresh = () => {
        let google = window.google;
        let map = this.state.map;

        if (!map || !google) return;

        try {
            google.maps.event.trigger(map, 'resize');
            if (map.getZoom) {
                map.setZoom(map.getZoom() - 1);
                map.setZoom(map.getZoom() + 1);
            }
        } catch (e) { console.error(e) }
    }

    addMapLoader = () => {
        let self = this;
        let google = window.google;
        let map = this.state.map;

        google.maps.event.addListener(map, 'bounds_changed', function () {
            self.setState({ mapLoading: true })

            google.maps.event.addListenerOnce(map, 'tilesloaded', function () {
                self.setState({ mapLoading: false })
            });
        });
    }

    addMapClickListener = () => {
        let self = this;
        let google = window.google;
        let map = this.state.map;
        google.maps.event.addListener(map, 'click', function (event) { //drop pin when user clicks on map
            let pos = new google.maps.LatLng(event.latLng.lat(), event.latLng.lng());
            // self.updateMarker(pos, window.location.origin + '/images/locationLoader.gif');
            self.updateMarker(pos);
        });
    }

    addMarker = (pos, icon = window.location.origin + '/images/locationPin.png') => {
        let self = this;
        let google = window.google;
        let map = this.state.map;
        let marker = new google.maps.Marker({
            position: pos,
            map: map,
            optimized: false,
            draggable: false,
            icon: {
                url: icon,
                scaledSize: new google.maps.Size(30, 37),
                size: new google.maps.Size(243, 288),
                anchor: new google.maps.Point(15, 37)
            },
        });
        marker.addListener('click', function () {
            self.updateInfoWindow(marker.position, self.handleMarkerInfowindow(self.props.coverageData));
        });
        return marker
    }

    updateMarker = (pos, icon = window.location.origin + '/images/locationPin.png') => {
        let marker = this.state.marker;
        if (marker) {
            marker.setPosition(pos);
            marker.setIcon(icon);
        } else {
            marker = this.addMarker(pos, icon)
        }
        if (this.props.markerChange) this.props.markerChange({ lat: marker.position.lat(), lng: marker.position.lng() })
        this.updateInfoWindow(marker.position, this.handleMarkerInfowindow(this.props.coverageData));
        this.setState({ marker: marker })
    }

    updateContent = () => {
        if (this.state.marker)
            this.updateInfoWindow(this.state.marker.position, this.handleMarkerInfowindow(this.props.coverageData));
    }

    setLayerState(layerIndex, layer) {
        if (!this.state.map || !this.state.map.overlayMapTypes) return;

        if (layer.data && layer.checked && layer.notWMS)
            this.handleDataLayers(layer)
        else if (layer.data && !layer.checked && layer.notWMS)
            this.removeDataLayer(layer.name)
        else if (!layer.data && !layer.checked)
            this.state.map.overlayMapTypes.setAt(layerIndex, null);
        else if (!layer.data && !layer.notWMS) this.addWMSLayers(layerIndex, layer);
    }

    render() {
        return (
            <div>
                <GoogleMaps
                    id="map"
                    options={this.state.mapOptions}
                    apikey={this.props.gmpKey}
                    onMapLoad={this.handleMapLoad}
                />
            </div>
        );
    }
}

// export default MapComponent;

export default MapComponent;
