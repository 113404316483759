import {
    ADD_CLIENT_REQUEST,
    ADD_CLIENT_SUCCESS,
    ADD_CLIENT_FAILURE,

    GET_CLIENT_REQUEST,
    GET_CLIENT_SUCCESS,
    GET_CLIENT_FAILURE,

    UPDATE_CLIENT_REQUEST,
    UPDATE_CLIENT_SUCCESS,
    UPDATE_CLIENT_FAILURE,

    DELETE_CLIENT_REQUEST,
    DELETE_CLIENT_SUCCESS,
    DELETE_CLIENT_FAILURE
} from "../actions" 
 
export default (
    state = {
        success: false,
        data: [],
        info: {},
        error: {}
    },
    action
) => {
    switch (action.type) {
        case ADD_CLIENT_REQUEST:
            return {
                ...state,
                success: true,
                error: false
            };
        case ADD_CLIENT_SUCCESS:
            return {
                ...state,
                success: true,
                info: action.obj
            };
        case ADD_CLIENT_FAILURE:
            return {
                ...state,
                success: false,
                error: action.error
            };
        case GET_CLIENT_REQUEST:
            return {
                ...state,
                success: true,
                error: false
            };
        case GET_CLIENT_SUCCESS:
            return {
                ...state,
                success: true,
                data: action.clients
            };
        case GET_CLIENT_FAILURE:
            return {
                ...state,
                success: false,
                error: action.error
            };
        case UPDATE_CLIENT_REQUEST:
            return {
                ...state,
                success: true,
                error: false
            };
        case UPDATE_CLIENT_SUCCESS:
            return {
                ...state,
                success: true,
                info: action.doc
            };
        case UPDATE_CLIENT_FAILURE:
            return {
                ...state,
                success: false,
                error: action.error
            };
        case DELETE_CLIENT_REQUEST:
            return {
                ...state,
                success: true,
                error: false
            };
        case DELETE_CLIENT_SUCCESS:
            return {
                ...state,
                success: true,
                info: action.doc
            };
        case DELETE_CLIENT_FAILURE:
            return {
                ...state,
                success: false,
                error: action.error
            };

        default:
            return state;
    }
};