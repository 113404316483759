
import React, { Component } from 'react';
import styles from './styles.module.css'
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import { useHistory } from 'react-router-dom';
import { withRouter } from "react-router-dom";

// const history = useHistory();
class AdminActions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false,
        }
    }

    handleProfileMenuOpen = (event) => {
        this.setState({ isMenuOpen: !this.state.isMenuOpen, anchorEl: event.currentTarget })
    }

    handleMenuClose = (event) => {
        this.setState({ isMenuOpen: false })
    }

    handleManageUsers = (event) => {
        this.handleMenuClose(event);
        if (this.props.handleManageUsers) this.props.handleManageUsers();
        this.props.history.push("/manage/users");
    }

    handleManageClients = (event) => {
        this.handleMenuClose(event);
        if (this.props.handleManageClients) this.props.handleManageClients();
        this.props.history.push("/manage/clients");
    }

    handleComparison = (event) => {
        this.handleMenuClose(event);
        if (this.props.handleComparison) this.props.handleComparison();
        this.props.history.push("/");
    }

    render() {
        return (
            <div className={styles.root} >
                <IconButton
                    onClick={this.handleProfileMenuOpen}
                    color="inherit">
                    {this.props.style && this.props.style === 'light' ?
                        <MenuIcon className={styles.light} />
                        :
                        <MenuIcon />
                    }
                </IconButton>
                {this.props.user && this.props.user.details && this.props.user.details.role === "admin" &&
                    <Menu
                        className={styles.accountMenu}
                        anchorEl={this.state.anchorEl}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        id="account-menu"
                        keepMounted
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={this.state.isMenuOpen}
                        onClose={this.handleMenuClose}
                    >
                        <div>
                            <MenuItem onClick={this.handleComparison}>Comparison Map</MenuItem>
                            <MenuItem onClick={this.handleManageUsers}>Manage Users</MenuItem>
                            <MenuItem onClick={this.handleManageClients}>Manage Clients</MenuItem>
                        </div>
                    </Menu>
                }
            </div>
        );
    }
}
export default withRouter(AdminActions);