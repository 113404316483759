import {
    CREATE_USER_REQUEST,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILURE,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_FAILURE,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE
} from "../actions"


export default (
    state = {
        success: false,
        doc: [],
        error: {}
    },
    action
) => {
    switch (action.type) {
        case CREATE_USER_REQUEST:
            return {
                ...state,
                success: true,
                error: false
            };
        case CREATE_USER_SUCCESS:
            return {
                ...state,
                success: true,
                doc: action.doc
            };
        case CREATE_USER_FAILURE:
            return {
                ...state,
                success: false,
                error: action.error
            };
        case GET_USER_REQUEST:
            return {
                ...state,
                success: true,
                error: false
            };
        case GET_USER_SUCCESS:
            return {
                ...state,
                success: true,
                doc: action.obj
            };
        case GET_USER_FAILURE:
            return {
                ...state,
                success: false,
                error: action.error
            };
        case UPDATE_USER_REQUEST:
            return {
                ...state,
                success: true,
                error: false
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                success: true,
                doc: action.doc
            };
        case UPDATE_USER_FAILURE:
            return {
                ...state,
                success: false,
                error: action.error
            };
        case DELETE_USER_REQUEST:
            return {
                ...state,
                success: true,
                error: false
            };
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                success: true,
                doc: action.doc
            };
        case DELETE_USER_FAILURE:
            return {
                ...state,
                success: false,
                error: action.error
            };

        default:
            return state;
    }
};