
import React, { Component } from 'react';
import styles from './styles.module.css'
import UserAvatar from '../UserAvatar';
import AdminActions from "../AdminActions";
import { Redirect } from 'react-router-dom'
import { AppBar, Toolbar, IconButton, Typography, Button } from '@material-ui/core';
import { logoutUser } from "../../actions";
import MenuIcon from '@material-ui/icons/Menu';

class NavBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false,
        }
    }

    handleLogout = () => {
        this.props.handleLogout();
    }

    render() {
        return (
            <div className={styles.root} >
                {this.props.onlyAvatar ?
                    <UserAvatar
                        user={this.props.user}
                        handleLogout={this.handleLogout}
                    /> :
                    <AppBar position="static">
                        <Toolbar>
                            <AdminActions
                                user={this.props.user}
                                style={'light'}
                            />
                            <Typography variant="h6" className={styles.title}>
                                {this.props.title}
                            </Typography>
                            <UserAvatar
                                user={this.props.user}
                                handleLogout={this.handleLogout}
                            />
                        </Toolbar>
                    </AppBar>
                }
            </div>
        );
    }
}
export default NavBar;