import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { loginUser, loginUserWithGoogle } from "../../actions";
import { withStyles } from "@material-ui/styles";
import utils from '../../Utils/utils'

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";

const styles = () => ({
    "@global": {
        body: {
            backgroundColor: "#fff"
        }
    },
    paper: {
        marginTop: 100,
        display: "flex",
        padding: 20,
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "#f50057"
    },
    form: {
        marginTop: 1
    },
    errorText: {
        color: "#f50057",
        marginBottom: 5,
        textAlign: "center"
    },
    orText: {
        color: "#3f51b5",
        marginTop: 5,
        textAlign: "center"
    },
    googleSubmit: {
        color: "#fff",
        backgroundColor: "#DB4437",
        marginTop: "5pt",
    }
});

class Login extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: "",
            password: "",
            loading: false
        }
    }

    handleEmailChange = ({ target }) => {
        this.setState({ email: target.value });
    };

    handlePasswordChange = ({ target }) => {
        this.setState({ password: target.value });
    };

    handleSubmit = () => {
        const { dispatch } = this.props;
        const { email, password } = this.state;
        dispatch(loginUser(email, utils.encode(this.state.password)));
    };

    handleSignInWithGoogle = () => {
        const { dispatch } = this.props;
        dispatch(loginUserWithGoogle());
        this.setState({loading: true})
    };
 

    render() {
        const { classes, loginError, isAuthenticated } = this.props;
        if (isAuthenticated) {
            return <Redirect to="/" />;
        } else {
            return (
                <Container component="main" maxWidth="xs">
                    <Paper className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <TextField
                            disabled={this.props.isLoggingIn && this.props.isAuthorizing}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            onChange={this.handleEmailChange}
                        />
                        <TextField
                            disabled={this.props.isLoggingIn && this.props.isAuthorizing}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            onChange={this.handlePasswordChange}
                        />
                        {loginError && (
                            <Typography component="p" className={classes.errorText}>
                                Incorrect email or password.
                            </Typography>
                        )}
                        <Button
                            disabled={this.props.isLoggingIn && this.props.isAuthorizing}
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.handleSubmit}
                        >
                            Sign In
                        </Button>
                        <Button
                            disabled={this.props.isLoggingIn && this.props.isAuthorizing}
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.googleSubmit}
                            onClick={this.handleSignInWithGoogle}
                        >
                            Sign in with Google
                        </Button>
                    </Paper>
                </Container>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        isLoggingIn: state.auth.isLoggingIn,
        loginError: state.auth.loginError,
        isAuthenticated: state.auth.isAuthenticated,
        isAuthorizing: state.auth.isAuthorizing,
        isAutorized: state.auth.isAthorized,
        user: state.auth.user
    };
}

export default withStyles(styles)(connect(mapStateToProps)(Login));