
import React, { Component } from 'react'; 
import IconButton from '@material-ui/core/IconButton'; 
import styles from './styles.module.css' 
import MenuItem from '@material-ui/core/MenuItem'; 
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
  
class UserAvatar extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isMenuOpen: false,
        }
    }
 
    handleProfileMenuOpen = (event) => {
        this.setState({ isMenuOpen: !this.state.isMenuOpen, anchorEl: event.currentTarget })
    }

    handleMenuClose = (event) => {
        this.setState({ isMenuOpen: false })
    }

    handleLogout = (event) => {
        this.handleMenuClose(event);
        this.props.handleLogout();
    }

    handleManageUsers = (event) => {
        this.handleMenuClose(event);
        this.props.handleManageUsers();
    }

    handleManageClients = (event) => {
        this.handleMenuClose(event);
        this.props.handleManageClients();
    }

    handleManageClients = (event) => {
        this.handleMenuClose(event);
        this.props.handleComparison();
    }

    render() {
        return (
            <div className={styles.root} >
                <IconButton
                    className={this.props.freeform ? styles.freeFromAvatar : styles.avatar}
                    edge="end"
                    aria-label="Current user"
                    aria-controls="account-menu"
                    aria-haspopup="true"
                    onClick={this.handleProfileMenuOpen}
                    color="inherit"
                >
                    <Avatar alt={this.props.user ? this.props.user.name : "User"} src={this.props.user ? this.props.user.photoURL : ''} />
                </IconButton>
                <Menu
                    className={styles.accountMenu}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    id="account-menu"
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={this.state.isMenuOpen}
                    onClose={this.handleMenuClose}
                > 
                    <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                </Menu>
            </div>
        );
    }
}
export default UserAvatar;