import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton'; 
import styles from './styles.module.css' 
import { ListSubheader, List, ListItem, ListItemIcon, CircularProgress, Checkbox, ListItemText } from '@material-ui/core';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';

const icon = < CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = < CheckBoxIcon fontSize="small" />;

class LayerList extends Component {

    constructor(props) {
        super(props);
    }
 
    handleToggle = (value) => {
        this.props.handleToggle(value)
    }

    handleExpandClick = (item) => {
        this.props.handleLayerExpand(item)
    }

    render() {
        return (
            <div className={styles.root}>
                <List subheader={
                    <ListSubheader disableSticky component="div" id="nested-list-subheader" >
                        {this.props.layerControlTitle ? this.props.layerControlTitle : 'Layer control'
                        } </ListSubheader>
                } >
                    {this.props.layers.map(value => {
                        return value.display && (
                            <div key={'layer-control-child-container-' + value.name} >
                                <ListItem key={value.name}
                                    role={undefined}
                                    dense >
                                    <ListItemIcon >
                                        {value.loading ?
                                            <CircularProgress className={styles.loader} />
                                            : <Checkbox
                                                color='primary'
                                                onClick={
                                                    () => { this.handleToggle(value) }
                                                }
                                                edge="start"
                                                checked={value.checked}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={
                                                    { 'aria-labelledby': value.displayName }
                                                }
                                            />
                                        }
                                    </ListItemIcon>

                                    <ListItem 
                                    style={{flexDirection: 'column', alignItems: 'start', paddingLeft: '0', paddingTop: '0' }}
                                    > 
                                        <ListItemText 
                                        id={`checkbox-list-label-${value.name}`} 
                                        primary={value.displayName} 
                                        style={{textAlign: 'left'}}
                                        />

                                        <ListItem
                                        style={{padding: '0'}}>
                                        { value.colors.map(c => {
                                            return <Box
                                                key={value.name + '_tab_' + c.name}
                                                aria-label={c.name}
                                                style={
                                                    {
                                                        backgroundColor: c.backgroundColor,
                                                        fontSize: '8pt',
                                                        fontWeight: 'bold',
                                                        color: 'rgb(26, 26, 26)',
                                                        padding: '4px 8px',
                                                        margin: '0 2px 6px 2px',
                                                        borderRadius: '4px',
                                                    }
                                                } > {c.name} </Box>
                                            })
                                        } 
                                        </ListItem>
                                    </ListItem>
                                </ListItem >

                            </div >
                        )
                    })
                    }
                </List>
            </div>
        );
    }
}
export default LayerList;